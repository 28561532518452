import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <center><img src="banner.png" alt="SG Bus" width="480" /></center>
    <br />
    <p>{`Get real-time updates on bus arrival times and never miss a bus again! Our app helps you navigate the Singapore public transport system with ease. Track your bus and plan your commute ahead of time. With accurate and up-to-date information, you'll always be on time. Download now and start enjoying hassle-free commuting!`}</p>
    <br />
    <h2 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Bus stop locations`}</p>
        <p parentName="li">{`Locate the nearest bus stop to your current location.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Real-time bus arrival times`}</p>
        <p parentName="li">{`Get up-to-date information on when the next bus is arriving at your stop.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bus route information`}</p>
        <p parentName="li">{`Find out which bus routes you can take to get to your destination.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bookmarks`}</p>
        <p parentName="li">{`Save your frequently used bus stops for quick access later.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Unified search`}</p>
        <p parentName="li">{`Make it even easier to search for bus stops.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`My EZ-Link card`}</p>
        <p parentName="li">{`Check the EZ-Link card balance and public transport transaction history on the go.`}</p>
      </li>
    </ul>
    <br />
    <h2 {...{
      "id": "download",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#download",
        "aria-label": "download permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download`}</h2>
    <p>{`Thank you for choosing our SG Bus App! Our app provides real-time bus arrival times, bus route information, and bus stop locations, making it easier for you to navigate the Singapore bus system.`}</p>
    <p>{`To download our app, please click on the links below:`}</p>
    <p><a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=com.scheung.sgbus"
      }}><img parentName="a" {...{
          "src": "download_google_play.svg",
          "alt": "GET IT ON Google Play"
        }}></img></a>{` `}{` `}<a parentName="p" {...{
        "href": "https://apps.apple.com/us/app/id1520884982"
      }}><img parentName="a" {...{
          "src": "download_app_store.svg",
          "alt": "Download on the App Store"
        }}></img></a></p>
    <p>{`Our app is free to download and use. Once you've downloaded it, you'll have access to all of its features.`}</p>
    <p>{`Our app is designed to be user-friendly and easy to navigate, so you can quickly find the information you need. If you have any questions or feedback, please don't hesitate to contact us at `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Thank you for choosing our SG Bus App. We hope it helps you have a smooth and hassle-free commute!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      